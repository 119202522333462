import React, { useState } from 'react'
import Text from '../../Core/Text'
import BlockWith2Cols from '../BlockWith2Cols'
import TestimonialImage from './TestimonialImage'
import TestimonialText from './TestimonialText'
import * as styles from './testimonial.module.scss'

export interface TestimonialItems {
  content: {
    media: {
      src: string
      gatsbyImageData: any
    }
    author: string
    authorTitle: string
    paragraph: string
    vimeoId?: string
  }
}

interface TestimonialProps {
  data: {
    contentTypeAlias: string
    title: string
    subtitle: string
    colorCombination: string
    testimonials: TestimonialItems[]
  }
}

export default function Testimonial(props: TestimonialProps) {
  const {
    data: {
      title,
      colorCombination = 'orange',
      testimonials,
      contentTypeAlias,
    },
  } = props

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const handleSlideChange = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <BlockWith2Cols
      id={contentTypeAlias}
      className={styles.testimonialMain}
      reverse
      colorCombination={colorCombination}
      mainContent={
        <TestimonialImage data={testimonials} slideIndex={currentIndex} />
      }
      bgContent={
        <div className={styles.testimonialAuthor}>
          <Text tag="h2" as="h2" className="testimonial-title">
            {title}
          </Text>
          <TestimonialText
            data={testimonials}
            onSlideChange={handleSlideChange}
          />
        </div>
      }
    />
  )
}
