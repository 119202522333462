import React, { useEffect } from 'react'
import { useState } from 'react'
import Swiper from 'swiper'
import { TestimonialItems } from '.'
import Slider from '../../Core/Slider'
import VideoProvider from '../../Core/VideoProvider'
import * as styles from './testimonial.module.scss'

interface TestimonialImage {
  data: TestimonialItems[]
  slideIndex: number
}

export default function TestimonialImage(props: TestimonialImage) {
  const { data, slideIndex } = props

  const [swiper, setSwiper] = useState<Swiper>()

  const handleInit = (swiper: Swiper) => {
    setSwiper(swiper)
  }

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(slideIndex)
    }
  }, [slideIndex])

  return (
    <div className={styles.testimonialSlide}>
      <Slider
        onInit={handleInit}
        navigation={false}
        effect="fade"
        draggable={false}
        data={data.map((d, index) => {
          const isVimeo = d.content.vimeoId && d.content.vimeoId !== ''
          const isVideo = d.content && d.content.media.src.includes('.mp4')

          return {
            content:
              isVimeo || isVideo ? (
                <VideoProvider
                  source={isVimeo ? 'vimeo' : 'upload'}
                  data={{
                    name: d.content.author,
                    description: d.content.paragraph || d.content.author,
                    contentUrl: isVimeo
                      ? d.content.vimeoId
                      : d.content.media.src,
                    embedUrl: isVimeo ? d.content.vimeoId : d.content.media.src,
                  }}
                />
              ) : (
                <img
                  src={d.content.media.src}
                  alt={d.content.author}
                  key={`i-${index}`}
                />
              ),
          }
        })}
      />
    </div>
  )
}
